// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "https://cdn.jsdelivr.net/npm/@coreui/coreui@4.1.0/dist/css/coreui.min.css";
// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";


// global general all custom
.text_area_none {
	resize: none;
}

.word_break {
	word-wrap: break-word;
}

// .padding_0_10px {
// 	padding: 0 10px;
// }
.execption_scroll_area {
	padding: 0 10px;
	height: 180px;
	/* overflow: hidden; */
	overflow-y: scroll;

}

.b_1px_gray {
	border: 1px solid rgba(136, 136, 136, 0.582);
}

.b_1_1px_gray {
	border: 1.1px solid rgba(136, 136, 136, 0.582) !important;
}

.no_border_shadow {
	border: 1px solid #c7c7c7;
	color: #464646;
	font-size: 15px;
	box-shadow: none !important;
	outline: none !important;
	border-radius: 3px;
}

.word_break_all {
	word-break: break-all;
}

.text_gray {
	color: #464646;
}

.desc_length {
	right: 30px;
	bottom: -13px;
}

.fs_16px {
	font-size: 16px;
}

.fs_13px {
	font-size: 13px;
}

.fs_1_1rem {
	font-size: 1.1rem;
}

.alert {
	color: #e6051a;
	background-color: none;
	border-color: none;
	padding: 0;
}

.cursor_no_drop {
	cursor: no-drop;
}

.cursor_pointer {
	cursor: pointer;
}

.forgot {
	width: 1100px;
	margin-left: 170px;
}

.reset {
	width: 1100px;
	margin-left: 170px;
}

.max_W_230 {
	max-width: 230px !important;
}

.W_230 {
	width: 250px !important;
}

.c-active {
	background-color: #4d5b74 !important;
}

.primary-color {
	color: #3C4B64 !important;
}

// .MuiFormControlLabel-root {
// 	margin-bottom: 0px;
// }
.disabled {
	cursor: no-drop !important;
	opacity: 0.45 !important;
}

.disabled_input {
	border: 1px solid #FFF;
	color: #999;
	cursor: no-drop;
}

// .MuiFormControlLabel-root input,
// .MuiFormControlLabel-root label {
// 	height: 30px;
// 	// width: 30px;
// }

.btn-facebook {
	color: #fff !important;
	background-color: #3b5998 !important;
	border-color: rgba(0, 0, 0, 0.2) !important;
	font-size: 14px !important;
	margin: 10px 5px 5px 0px;
}

.btn-google {
	color: #fff !important;
	background-color: #dd4b39 !important;
	border-color: rgba(0, 0, 0, 0.2) !important;
	font-size: 14px !important;
	margin: 10px 0px 5px 0px;
}

.btn-social {
	position: relative !important;
	padding-left: 44px !important;
	text-align: left !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.btn-social:hover {
	color: #eee !important;
}

.no-wrap {
	white-space: nowrap;
}

.text-center {
	text-align: center;
}

.btn-social :first-child {
	position: absolute !important;
	left: 0 !important;
	top: 0 !important;
	bottom: 0 !important;
	padding: 7px !important;
	text-align: center !important;
	border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.btn-social .f-icon,
.btn-social .g-icon {
	width: 35px !important;
	height: 35px !important;
}

.social-child-txt {
	margin-left: -4px;
}

.custom-loader {
	display: inline-block;
	width: 18px;
	height: 18px;
	margin-right: 10px;
	vertical-align: text-bottom;
	border: 2px solid;
	border-right: 2px solid transparent;
	border-radius: 50%;
	animation: spinner-border .75s linear infinite;
}

.forgot {
	width: 1100px;
	margin-left: 450px;
}

.reset {
	width: 1100px;
	margin-left: 550px;
}

.file-upload {
	padding: 0;

	&:hover {
		text-decoration: none;
	}
}

.passwordfield {
	margin: 0 auto;
}

.iconfileld {
	position: absolute;
	right: 6%;
	top: 11%;
}

/*
 * CSS INDEX
 * #-Login From
 * #-Single Input
 * #-Button
 * #-Profile Info
 * #single-dropdown
 * #-Single Search From
 * #-Pagination
 * #-Bid Details
 * #-Footer
 *
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	color: #191919;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-style: normal;
}

a,
button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

*::-moz-selection {
	background: #d6b161;
	color: #fff;
	text-shadow: none;
}

::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}

*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}

*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	color: #013a6d;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	text-decoration: none;
	transition: .4s;
	-webkit-transition: all .4s ease-in-out;
}

a:hover {
	text-decoration: none;
	color: #82B60B;
}

button:focus {
	outline: none;
}

input:focus {
	outline: none;
}

textarea:focus {
	outline: none;
}

p {
	color: #191919;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-25 {
	padding-top: 25px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-35 {
	padding-top: 35px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-45 {
	padding-top: 45px;
}

.pt-50 {
	padding-top: 50px;
}

.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-35 {
	padding-bottom: 35px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-45 {
	padding-bottom: 45px;
}

.pb-50 {
	padding-bottom: 50px;
}

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-35 {
	margin-bottom: 35px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-45 {
	margin-top: 45px;
}

.mt-50 {
	margin-top: 50px;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1240px;
	}
}

img {
	max-width: 100%;
}

.login-page {
	min-height: 100vh;
	padding: 50px 0px;
	display: flex;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

/*
 * #-Login From
*/
.login-from {
	background: #eeeeeeeb;
	padding: 35px 35px;
	max-width: 550px;
	margin: 0 auto;
}

.login-from h2.title {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 10px;
}

.social-login {
	padding-top: 10px;
	margin-bottom: 30px;
	overflow: hidden;
}

.social-login ul li {
	display: inline-block;
	text-align: center;
	width: 23%;
	float: left;
	margin: 0% 1%;
}

.social-login ul li.google {
	width: 48%;
	margin-left: 0px;
}

.social-login ul li.facebook a {
	width: 100%;
	display: block;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	text-transform: capitalize;
	border-radius: 5px;
	padding: 13px 0px;
	background: #36528C;
}

.social-login ul li.linkedin a {
	width: 100%;
	display: block;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	text-transform: capitalize;
	border-radius: 5px;
	padding: 13px 0px;
	background: #0D8DDC;
}

.social-login ul li.google a {
	background: #D04237;
	width: 100%;
	display: block;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
	border-radius: 5px;
	padding: 15px 0px;
}

.social-login ul li.google a i {
	font-size: 20px;
}

@media (max-width: 767px) {
	.login-page-img {
		display: none;
	}
}

@media (max-width: 360px) {
	.login-from {
		padding: 20px 12px;
		max-width: 550px;
	}
}

/*
 * #-Single Input
*/
.single-input {
	width: 100%;
}

.single-input input {
	width: 100%;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	padding: 16px 15px;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	color: #666;
	font-weight: 500;
	font-size: 14px;
}

.single-input input::-moz-placeholder {
	color: #666;
	font-size: 14px;
	opacity: 1;
	font-weight: 500;
	text-transform: capitalize;
}

.single-input input::placeholder {
	color: #666;
	font-size: 14px;
	opacity: 1;
	font-weight: 500;
	text-transform: capitalize;
}

.single-input label {
	font-size: 14px;
	font-weight: 600;
	color: #000;
	margin-bottom: 6px;
}

.single-input p {
	font-size: 15px;
	color: #666;
}

.single-input p a {
	font-weight: 600;
	color: #444;
}

.single-input p a:hover {
	color: #D04237;
	text-decoration: underline;
}

/*
 * #-Button
*/
.button-1 {
	background: #013a6d;
	border: none;
	color: #fff;
	font-weight: 600;
	font-size: 15px;
	text-transform: uppercase;
	padding: 11px 40px;
	border-radius: 6px;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	display: inline-block;
}

.button-1:hover {
	background: #0b62b0;
	color: #fff;
}

/*
 * #-Profile Info
*/
.profile-info-top {
	border-bottom: 1px solid #eee;
	padding-top: 20px;
	padding-bottom: 20px;
}

h2.profile-info-title {
	font-size: 20px;
	text-transform: capitalize;
	font-weight: 700;
}

.profile-top img {
	width: 50px;
	border: 2px solid #eee;
	border-radius: 50%;
	cursor: pointer;
}

.profile-top .dropdown-menu {
	border-radius: 0px;
	inset: 0px auto auto 0px !important;
	border-color: #eeeeee75;
	padding: 0;
	width: 160px !important;
}

.profile-top ul li a {
	padding: 10px 17px;
	border-bottom: 1px solid #eee;
	color: #666;
	font-size: 14px;
}

.profile-top button {
	padding: 0;
	border: none;
	background: transparent;
}

/*
 * #-single-dropdown
*/
.single-dropdown .label {
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 600;
	margin-bottom: 10px;
}

.single-dropdown .form-select {
	border-radius: 0px;
	border: 1px solid #eee;
	font-weight: 600;
	font-size: 16px;
	padding: 10px 12px;
}

.single-dropdown .form-select:focus {
	box-shadow: none;
}

.single-dropdown .form-select option {
	font-weight: 400;
}

.das-bid-table thead {
	background: #cccccc36;
}

.das-bid-table thead th {
	padding: 10px 20px;
}

.latest-bid-tabel h2.title {
	font-size: 24px;
	text-transform: capitalize;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 20px;
}

.das-bid-table tbody td {
	padding: 10px 20px;
}

.das-bid-table tbody td a {
	color: #666;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.das-bid-table tbody td a:hover {
	text-decoration: underline;
	color: #f33;
}

/*
 * #-Single Search From
*/
.single-serach-from {
	width: 100%;
	position: relative;
}

.single-serach-from input {
	width: 100%;
	border: 1px solid #ddd;
	padding: 10px 14px;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.single-serach-from button {
	position: absolute;
	right: 0;
	height: 100%;
	border: none;
	background: #013a6d;
	color: #fff;
	padding: 0px 16px;
}

/*
 * #-Pagination
*/
.s-pagination ul li {
	display: inline-block;
	margin: 0px 3px;
}

.s-pagination ul li a {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #eee;
	width: 35px;
	height: 35px;
	color: #333;
	background: #fff;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.s-pagination ul li a:hover {
	border-color: #013a6d;
	color: #fff;
	background: #013a6d;
}

.s-pagination ul li span {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #013a6d;
	width: 35px;
	height: 35px;
	color: #fff;
	background: #013a6d;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

/*
 * #-Bid Details
*/
.biddetails-content .single {
	margin-bottom: 30px;
}

.biddetails-content .single h3 {
	font-size: 20px;
	text-transform: capitalize;
	font-weight: 700;
	margin-bottom: 10px;
}

.biddetails-content .single p {
	color: #666;
	line-height: 30px;
	font-size: 16px;
	border: 1px solid #eee;
	padding: 10px 20px;
}

.bid-deadline h4 {
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 600;
	color: #222;
	margin-bottom: 10px;
}

.bid-deadline .date span {
	border: 1px solid #71636340;
	float: left;
	padding: 4px 15px;
	font-size: 14px;
	font-weight: 600;
	color: #666;
}

/*
 * #-Footer
*/
.footer {
	background: #000;
	color: #fff;
	padding: 22px 0px;
}

.footer p {
	color: #fff;
	font-size: 15px;
	line-height: 30px;
}

@media (max-width: 991px) {
	.footer .text-right {
		text-align: left;
	}
}

@media (max-width: 576px) {
	.bid-deadline {
		margin-bottom: 20px;
		overflow: hidden;
	}

	.screen-991 {
		float: left;
		text-align: left;
		margin-bottom: 30px;
	}
}









/*
 * Siderbar
*/
.psidebar-menu {
	border: 1px solid #ddd;
	text-align: center;
}

.psidebar-header {
	padding-top: 30px;
	padding-bottom: 30px;
	background: #eee;
}

.psidebar-header .img {
	position: relative;
	width: 120px;
	height: 120px;
	margin: 0 auto;
	border: 2px solid #74b2f4;
	border-radius: 50%;
}

.psidebar-header .img img {
	width: 100%;
	height: 100%;
}

.psidebar-header .img .upload-img input {
	display: none;
}

.psidebar-header .img .upload-img label {
	width: 25px;
	height: 25px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	box-shadow: 0px 0px 4px 0px #6c6c6c;
	font-size: 12px;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
	cursor: pointer;
}

.psidebar-header .img .upload-img label:hover {
	background: #013a6d;
	color: #fff;
}

.psidebar-header .img .upload-img {
	position: absolute;
	bottom: 21px;
	right: -6px;
}

.psidebar-header .form-select {
	width: auto;
	margin: 0 auto;
	text-align: center;
	background-color: #ddd;
	color: #013a6d;
	text-transform: uppercase;
	font-size: 15px;
	border-radius: 30px;
	padding: 6px 20px;
	padding-right: 28px;
	padding-left: 10px;
}

.psidebar-header .content {
	padding-top: 15px;
}

.psidebar-header .content .name {
	font-size: 14px;
	margin-top: 10px;
	font-weight: 600;
}

.psidebar-menu-list ul li {
	display: inline-block;
	width: 100%;
}

.psidebar-menu-list ul li a {
	display: inline-block;
	border-bottom: 1px solid #dddddd80;
	width: 100%;
	padding: 12px 10px;
	font-size: 15px;
	font-weight: 400;
	color: #555;
	text-transform: capitalize;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.psidebar-menu-list ul li a:hover {
	background: #ddd;
}

.psidebar-menu-list ul li span {
	display: inline-block;
	width: 100%;
	padding: 12px 10px;
	font-size: 14px;
	font-weight: 400;
	color: #888;
	text-transform: capitalize;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.asterisk:after {
	content: " * ";
	color: red;
	font-weight: bold;
}