// Here you can add other styles
.tbl_page_title {
    font-size: 22px;
}

.table th {
    border-top: none !important;
}

.table tr:hover {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.asterik {
    color: #FF0000;
}

.margin-zero {
    margin: 0;
}

.padding-zero {
    padding: 0;
}

div.DraftEditor-root {
    border-color: #d8dbe0 !important;
    height: 250px !important;
    overflow-y: scroll !important;
    padding: 0.375rem 0.75rem;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.btn-custom-link {
    color: blue;
}
